// cssnano produces improper output in postcss-normalize-positions
// clean-css
// this is a temp fix to avoid the issue where
// input:
// background-position: center right $input-height-inner-quarter;
// produces output:
// background-position: 100% $input-height-inner-quarter;

.was-validated .form-control:valid, .form-control.is-valid {
  background-position: right $input-height-inner-quarter center;
 }

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-position: right $input-height-inner-quarter center;
 }

.filter-label {
  width: 100%;
}

.date-filter,
.number-filter {
  display: inline-flex;
  width: 100%;
}

.date-filter .filter-label,
.number-filter .filter-label {
  width: 67px;
  margin-right: 8px;
}

.react-bootstrap-table > table > thead > tr > th .number-filter-comparator,
.react-bootstrap-table > table > thead > tr > th .date-filter-comparator {
  float: left;
}

.react-bootstrap-table-pagination {
  margin-top: 24px;
}

.react-bootstrap-table thead th {
  vertical-align: top;
}

.sidebar-minimized .sidebar .nav-item:hover {
  width: 49px;
  overflow: hidden;
}

.nav-title {
    & i {
        display: none !important;
    }
}
