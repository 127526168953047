// Here you can add other styles

$feed-upload-header-height: 29px;
$dark-input-bg-color: #515b65;
$dark-input-text-color: #e4e7ea;
$shopping-text-color: #424242;

.btn-group-xs > .btn, .btn-xs {
    padding: .25rem .4rem;
    font-size: .875rem;
    line-height: .5;
    border-radius: .2rem;
}

.cursor-pointer {
    cursor: pointer;
}

table td.td-input {
    padding: 0.3rem
}

@include media-breakpoint-up(xl) {
    .border-between > [class*='col-']:before{
        background: #e3e3e3;
        bottom: 0;
        content: " ";
        left: 0;
        position: absolute;
        width: 1px;
        top: $feed-upload-header-height
    }

    .border-between > [class*='col-']:first-child:before {
        display: none;
    }
}

.feed-upload-header {
    height: $feed-upload-header-height;
    margin-bottom: 1rem;
}

.margin-bottom {
    margin-bottom: 1.5rem
}

.margin-top {
    margin-top: 1.5rem;
}

.cursor-pointer{
    cursor: pointer;
}

.is-invalid.multi-select > div:first-child {
    @extend .form-control, .is-invalid;
    border: 1px solid #f86c6b !important;
    padding-right: 0;
    background-image: none;
}

.invalid-feedback-show {
    @extend .invalid-feedback;
    display: block;
}

.multi-select {
    flex: 1 1 auto;


    .Select-control,
    > div:first-child {
        color: $dark-input-text-color;
        background-color: $dark-input-bg-color;
        border: 1px solid #23282c !important;
        border-radius: 0.25rem;
        @extend .Select-control;
    }
}

.multi-select.Select.is-focused {
    .Select-control {
        background-color: $dark-input-bg-color;
    }
}

.multi-select.Select.is-focused:not(.is-open) {
    .Select-control {
        background-color: $dark-input-bg-color;
    }
}

.Select-input> input {
    color: $dark-input-text-color;
}

.multi-select.Select.has-value.Select--single>.Select-control .Select-value .Select-value-label,
.multi-select.Select.has-value.is-pseudo-focused.Select--single>.Select-control .Select-value .Select-value-label {
    color: $dark-input-text-color;
}

.multi-select.Select.is-disabled>.Select-control {
    background-color: #3a4149;
}

.truncate-60 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60ch;
}

.font-size-13 {
    font-size: 13px;
}

.font-size-14 {
    font-size: 14px;
}

.toast-button-container {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;
}

.word-break {
    word-break: break-all;
}

@include media-breakpoint-down(xs) {
    .hide-xs {
        display: none;
    }

    .optimal-img-preview {
        max-width: 100%;
    }
}

@include media-breakpoint-down(lg) {
    .hide-lg {
        display: none;
    }
}

.input-group .Select {
    flex: 1 1 auto;
}

.disabled-link {
    pointer-events: none;
    color: gray;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    max-width: 400px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}

.img-scale {
    max-width: 100%;
    max-height: 100vh;
    height: auto;
}

.overflow-auto {
    overflow: auto;
}

.min-width-1700 {
    min-width: 1700px;
}

.min-width-2300 {
    min-width: 2300px;
}

.badge-hoverable:hover {
    cursor: pointer;
}

.badge-warning.badge-hoverable:hover {
    background-color: #d39e00;
}

.badge-danger.badge-hoverable:hover {
    background-color: #f63c3a;
}

.ml6 {
    margin-left: 6px;
}

.message-danger {
    color: var(--white);
    background-color: var(--red);
}

.message-danger > .arrow::after {
    border-bottom-color: var(--red);
}

.select-filter option {
    color: var(--white) !important;
}

.ql-editor{
    max-height: 500px;
    overflow: hidden;
    overflow-y: scroll;
    background-color: var(--white);
    color: $shopping-text-color
}

.table-auto {
    table-layout: auto !important;
}

.table-auto td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;
}

.max-width-100-percent {
    max-width: 100%;
}

.hidden-file-input {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.m-b-0 {
    margin-bottom: 0 !important;
}

.m-l-small {
    margin-left: 0.5rem
}

.m-r-15 {
    margin-right: 15px;
}

.shopping-logo-image {
    max-width: 119px;
    max-height: 111px;
    margin: auto;
}

.width-100-height-100 {
    width: 100%;
    height: 100%;
}

.shopping-logo-image-container {
    width: 207px;
    height: 178px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shopping-bg-image {
    object-fit: cover;
    object-position: top;
    width: 1920px;
    height: 209px;
}

.optimal-img-preview {
    max-height: 300px;
}

.m-t-40 {
    margin-top: 40px;
}

.margin-10 {
  margin: 10px
}

.max-width-70-percent {
  max-width: 70%;
}

.max-width-70-percent {
  max-width: 70%;
}

.keycloak-tutorial-image-container {
    max-width: 1000px;
}

.keycloak-tutorial-image {
    max-width: 100%;
    margin-bottom: 15px;
}
